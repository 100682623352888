import { useEffect, useState } from 'react';
import {
  useShowAdminActions,
  useUserPermissions,
} from '@cfacorp/ctrl-platform-shared-react-components';

const useHasViewPointPerms = () => {
  const cfaPerms = useUserPermissions();
  const showAdminActions = useShowAdminActions();
  const [hasViewPointPerms, setHasViewPointPerms] = useState(true);
  const [permsLoaded, setPermsLoaded] = useState(false);

  useEffect(() => {
    if (cfaPerms) {
      const isAuthorized =
        !!cfaPerms.SP_CONFIG?.SUPER_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.VIEWPOINT_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.VIEWPOINT_USER?.length;
      setHasViewPointPerms(isAuthorized);
      setPermsLoaded(true);
    }
  }, [cfaPerms]);

  return {
    hasViewPointPerms: hasViewPointPerms && showAdminActions,
    permsLoaded,
  };
};

export default useHasViewPointPerms;
