import { lazy, Suspense, useEffect, useMemo } from 'react';
import './i18n';
import {
  appLoadingStatuses,
  AppNames,
  cartDataPointsGroups,
  globalRoutes,
  userPermissions,
} from '@cfacorp/ctrl-platform-ui-core-utils';
import RouteInfo from '@cfacorp/ctrl-platform-ui-core-utils/dist/types/RouteInfo';
import useHasViewPointPerms from './hooks/useHasViewPointPerms';
import { appDataPointGroups } from './enums/AppDataPoints';
import useAppTranslation from './hooks/useAppTranslation';
import RouteConstants from './routes/RouteConstants';

const AppRouter = lazy(() => import('./routes/AppRouter'));

export default function Root() {
  const { t } = useAppTranslation();
  //const hasViewPointFeatureFlag = useHasFeatureFlag(FeatureFlags.VIEWPOINT);
  const { hasViewPointPerms, permsLoaded } = useHasViewPointPerms();
  const routes: RouteInfo[] = useMemo(
    () => [
      {
        route: RouteConstants.BASE_NAME,
        displayName: t('AppRoutes.baseName'),
        keywords: ['ViewPoint'],
      },
      {
        route: RouteConstants.CREATE_PROFILE,
        displayName: t('AppRoutes.createProfile'),
        keywords: ['CreateProfile'],
      },
    ],
    [t],
  );

  useEffect(() => {
    cartDataPointsGroups.next({
      ...cartDataPointsGroups.value,
      [AppNames.VIEWPOINT]: appDataPointGroups,
    });
  }, []);

  useEffect(() => {
    if (permsLoaded) {
      userPermissions.next({
        ...userPermissions.value,
        hasViewPointAccess: hasViewPointPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        viewPointLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...routes],
      });
    }
  }, [hasViewPointPerms, permsLoaded, routes]);

  return (
    <Suspense fallback={null}>
      <AppRouter />
    </Suspense>
  );
}
