import { FC, PropsWithChildren } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DndProvider } from 'react-dnd';

const queryClient = new QueryClient();

const Providers: FC<PropsWithChildren> = ({ children }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </DndProvider>
  );
};

export default Providers;
