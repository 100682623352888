enum RouteConstants {
  ACTIVITY_LEVELS = '/activitylevels',
  ALL_TRANSACTIONS = '/alltransactions',
  BASE_NAME = '/viewpoint',
  CREATE_PROFILE = '/vpprofile/createprofile',
  EDIT_PROFILE = '/vpprofile/:profile_id',
  EDIT = '/vpprofile/:profile_id/edit',
  HOME = '/',
  ITEM_LABEL_SPECIALS = '/servicereceipts/itemlabelspecials',
  ITEM_LEVEL = '/itemlevelrelease',
  MENU_ITEM_SORTING = '/menusorting/itemsorting',
  MENU_SORTING = '/menusorting',
  ORDER_DESTINATIONS = '/orderdestinations',
  ORDER_STATES = '/orderstates',
  ROUTED_ITEMS = '/servicereceipts/routeditems',
  SERVICE_RECEIPTS = '/servicereceipts',
  SPECIAL_ITEMS = '/servicereceipts/specialItems',
  VP_PROFILE = '/vpprofile',
}

export default RouteConstants;
